import { loadStripe } from '@stripe/stripe-js';

const stripePublicKey = process.env.VUE_APP_STRIPE_PUBLIC_KEY

let stripe = undefined;

export async function initStripe (){
    if (!stripe) {
        stripe = await loadStripe(stripePublicKey);
    }

    return stripe
}