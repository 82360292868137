import axios from 'axios';


export function indexBooking(page = 1) {
    const url = `v1/coach/booking?page=${page}`

    return axios.get(url).then(response => response.data)
}

export function createBooking(form) {
    const url = `v1/coach/booking/${form.game_id}`

    return axios.post(url).then(response => response.data.booking)
}