<template>
  <DashboardWrapper>
    <div v-if="game" class="container mx-auto">
      <div class="flex items-center mb-4 mx-4 sm:mx-0">
        <router-link
          :to="{ name: 'game.index' }"
          class="text-gray-500 uppercase text-xl font-extrabold hover:text-white transition-all duration-200"
        >
          <i18n-t keypath="game.show.title" />
        </router-link>
        <svg
          class=" h-6 w-6 text-white"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path
            class="my-auto"
            fill-rule="evenodd"
            d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
            clip-rule="evenodd"
          />
        </svg>

        <h1 class="text-white uppercase text-xl font-extrabold">
          {{ game.video.title }}
        </h1>
      </div>
      <div class="text-white mb-8 cursor-pointer">
        <router-link
          v-if="game?.reviews.length"
          :to="{ name: 'review', params: { id: game.reviews[0].id } }"
          unselectable="on"
        >
          <button class="button">
            <i18n-t keypath="game.create.goToReview" />
          </button>
        </router-link>
        <button
          v-else
          class="button"
          @click.prevent="createBooking(game)"
        >
          <i18n-t keypath="game.create.bookGame" />
        </button>
      </div>

      <Video :video-url="game.video.path" :thumbnail="game.video.thumbnail" />

      <div class="mt-10 grid gap-10 lg:grid-cols-3">
        <div
          class="bg-scorelitgray px-8 py-8 lg:col-span-2 shadow sm:rounded-md"
        >
          <div class="sm:flex border-b">
            <h2 class="text-white text-xl font-bold pb-3">
              {{ game.video.title }}
            </h2>
          </div>
          <h3 class="text-white mt-6 font-bold">
            <i18n-t keypath="game.show.team" />
          </h3>
          <p class="text-white text-sm mt-1">
            {{ game.team?.name }}
          </p>
          <h3 class="text-white mt-6 font-bold">
            <i18n-t keypath="game.show.created_at" />
          </h3>
          <p class="text-white text-sm mt-1">
            {{ dateToHumanReadable(game.created_at) }}
          </p>
          <h3 class="text-white mt-6 font-bold">
            <i18n-t keypath="game.show.description" />
          </h3>
          <p class="text-white text-sm mt-1">
            {{ game.video.description }}
          </p>
        </div>

        <div class="bg-scorelitgray px-8 py-10 shadow sm:rounded-md">
          <div class="flex border-b pb-2">
            <h3 class="text-white font-bold">
              <i18n-t keypath="game.show.trainees" />
            </h3>
          </div>
          <ol class="text-white text-sm ml-2">
            <li
              v-for="trainee in game.team?.trainees ?? []"
              :key="trainee.id"
              class="mt-2 group flex"
            >
              {{ trainee.name }}
            </li>
          </ol>
        </div>
      </div>
    </div>
    <div v-else class="flex justify-center">
      <svg
        class="animate-spin w-8 h-8"
        fill="none"
        stroke="white"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M8 12h.01M12 12h.01M16 12h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
        />
      </svg>
    </div>
  </DashboardWrapper>
</template>

<script>
import { defineComponent } from "vue";
import DashboardWrapper from "@/wrappers/Dashboard";
import { getGame } from "@/helper/api/GameHelper";
import { initStripe } from "@/stripe";
import { createBooking } from "@/helper/api/BookingHelper";
import Video from "@/components/review/Video";
import { dateToHumanReadable } from "@/helper/javascript/HumanReadableDate";

export default defineComponent({
  name: "GameShow",
  props: {
    id: {
      required: true,
      type: String,
    },
  },
  created() {
    this.getGame();
    this.initStripe();
  },
  data() {
    return {
      game: undefined,
      stripe: undefined,
    };
  },
  methods: {
    dateToHumanReadable,
    async getGame() {
      this.game = await getGame(this.id);
    },
    async createBooking(game) {
      const { stripe_session } = await createBooking({ game_id: game.id });

      this.stripe.redirectToCheckout({
        sessionId: stripe_session.id,
      });
    },
    async initStripe() {
      this.stripe = await initStripe();
    },
  },
  components: {
    DashboardWrapper,
    Video,
  },
});
</script>
